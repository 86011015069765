<template>
    <div>
        <portal to="title">
            Edit profile
        </portal>

        <DsSpinner v-if="loading" />

        <template v-else>
            <DsInlineAlert v-if="isAdmin && userId !== user.uid" leading-icon type="warning">
                <div>
                    Warning: You're using your <DsStatus emphasis="high">
                        Admin
                    </DsStatus>

                    privileges to edit this profile on behalf of <strong>{{ user.name }}</strong>.
                </div>
            </DsInlineAlert>

            <form>
                <DsAvatar
                    :size="96"
                    :name="user.name"
                    :email="user.email"
                    :image-url="user.photoUrl"
                    class="user-avatar"
                />

                <DsInputField
                    v-model.trim="user.name"
                    class="name"
                    label="Name"
                    :submitted="submitted"
                />

                <DsInputField
                    v-model.trim="user.gitHubHandle"
                    label="Enter your GitHub handle"
                    :submitted="submitted"
                />

                <DsInputField
                    v-model.trim="user.shirtSize"
                    label="T-shirt size"
                    :submitted="submitted"
                />

                <DsInputField
                    v-model.trim="user.dietaryRestrictions"
                    label="Dietary restrictions"
                    :submitted="submitted"
                />

                <DsTextAreaField
                    v-model.trim="user.shippingAddress"
                    label="Shipping address"
                    :submitted="submitted"
                >
                    <template #help>
                        Shipping address is only visible to admins and will be used to send t-shirts and prizes.
                    </template>
                </DsTextAreaField>

                <DsFilledButton
                    :loading="saving"
                    @click.prevent="updateUser"
                >
                    Update
                </DsFilledButton>
            </form>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import '@/assets/images/page-not-found.svg';

export default {
    data() {
        return {
            user: {},
            saving: false,
            loading: true,
            submitted: false,
        };
    },

    mounted() {
        if (this.canEdit) {
            this.loadUser();
        } else {
            this.$router.push({ name: 'home' });
        }
    },

    computed: {
        canEdit() {
            return this.isAdmin || this.userId === this.$route.params.id;
        },

        userProjects() {
            return this.getProjectsByUser(this.$route.params.id);
        },

        ...mapGetters({
            getProjectsByUser: 'projects/getProjectsByUser',
            getAwardByProject: 'projects/getAwardByProject',
        }),

        ...mapState({
            isAdmin: ({ auth }) => auth.user.isAdmin,
            userId: ({ auth }) => auth.user.uid,
        }),
    },

    methods: {
        formatDate(date) {
            return moment(date).fromNow();
        },

        async loadUser() {
            this.loading = true;

            this.user = await this.$store.dispatch('users/LOAD_USER', this.$route.params.id)
                .catch((e) => {
                    this.$error({ message: `Error adding GitHub handle: ${e}` });
                });

            this.loading = false;
        },

        async updateUser() {
            this.saving = true;

            await this.$store.dispatch('users/EDIT_USER', this.user)
                .then(() => {
                    this.$router.push({ name: 'user', params: { id: this.user.uid } });
                })
                .catch((e) => {
                    this.$error({ message: `Error adding GitHub handle: ${e}` });
                });

            this.submitted = true;
            this.saving = false;
        },
    },
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/styles/empty";

    h3,
    .user-avatar {
        margin-bottom: $spacing-400;
    }

    .date-joined {
        margin-top: $spacing-200;
    }
</style>
