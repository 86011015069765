<template>
    <div>
        <DsSpinner v-if="loading" />

        <template v-else>
            <div class="profile-info">
                <DsAvatar
                    style="display: block; margin: 0 auto;"
                    :size="96"
                    :name="user.name"
                    :email="user.email"
                    :image-url="user.photoUrl"
                />

                <h3>
                    {{ user.name }}
                </h3>

                Joined {{ formatDate(user.creationTime) }}

                <DsOutlineButton
                    v-if="canEdit"
                    @click="$router.push({ name :'user.edit' })"
                >
                    <DsStatus emphasis="high">
                        Admin
                    </DsStatus>

                    Edit
                </DsOutlineButton>
            </div>

            <h3 v-if="myProjects.length">
                Projects
            </h3>

            <ProjectsList
                v-if="myProjects.length"
                :projects="myProjects"
                @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
            />
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import ProjectsList from '@/components/ProjectsList';
import '@/assets/images/page-not-found.svg';

export default {
    components: {
        ProjectsList,
    },

    props: {
        editing: Boolean,
    },

    data() {
        return {
            localUser: {},
            gitHubHandle: '',
            saving: false,
            loading: true,
        };
    },

    created() {
        this.loadUser();
    },

    computed: {
        canEdit() {
            return this.isAdmin || this.userId === this.$route.params?.id;
        },

        myProjects() {
            return this.getProjectsByUser(this.$route.params?.id);
        },

        ...mapGetters({
            getProjectsByUser: 'projects/getProjectsByUser',
        }),

        ...mapState({
            userId: ({ auth }) => auth.user.uid,
            user: ({ users }) => users.user,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),
    },

    methods: {
        formatDate(date) {
            return moment(date).fromNow();
        },

        async loadUser() {
            this.loading = true;
            await this.$store.dispatch('users/LOAD_USER', this.$route.params?.id)
                .catch((e) => {
                    this.$error({ message: `Error adding GitHub handle: ${e}` });
                });
            this.loading = false;
        },

        edit() {
            this.$refs.editProfileModal.open();
        },
    },
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/styles/empty";

    .date-joined {
        margin-top: $spacing-200;
    }

    .user-page {
        display: grid;
        margin: $spacing-200;
        overflow: hidden;
        background: $color-white;
        border-radius: $border-radius;
    }

    .scroll-wrap {
        height: calc(100vh - 120px);
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    .profile-info {
        text-align: center;
        justify-content: center;
        display: grid;
        gap: $spacing-200;
    }
</style>
