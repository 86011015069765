<!-- TODO: rename hackathon -> event -->
<template>
    <div>
        <form @submit.prevent="submit">
            <portal to="title">
                {{ title }}
            </portal>

            <div v-if="loading">
                loading
            </div>

            <template v-else-if="hackathon">
                <div v-if="editing" class="image-controls">
                    <DsTextButton
                        leading-icon="trash-2"
                        gray
                        @click.native="removeImageCheck"
                    >
                        Delete image
                    </DsTextButton>

                    <DsTextButton
                        :loading="uploadingPhoto"
                        leading-icon="upload-cloud"
                        gray
                        @click="togglePhotoUpload"
                    >
                        Upload image
                    </DsTextButton>

                    <DsFileUploadInput
                        ref="uploader"
                        v-model="photoUploader.file"
                        :progress.sync="photoUploader.progress"
                        :max-file-size-bytes="maxFileSize"
                        @input="uploadFile($event)"
                        @error="handleError"
                    />

                    <DsFileUploadInput
                        v-show="false"
                        ref="photoUploader"
                        v-model="photoUploader.file"
                        :progress.sync="photoUploader.progress"
                        :max-file-size-bytes="maxFileSize"
                        @input="uploadPhoto($event)"
                        @error="handleError"
                    />
                </div>

                <DsInputField
                    v-model="hackathon.name"
                    name="name"
                    label="Hackathon name"
                    required
                />

                <DsTextAreaField
                    v-model="hackathon.description"
                    name="description"
                    label="Hackathon description"
                >
                    <div slot="help">
                        <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">
                            <small>Markdown supported</small>
                        </a>
                    </div>
                </DsTextAreaField>

                <div class="input-row">
                    <DsDatePicker
                        v-model="hackathon.startDate"
                        class="date"
                        time
                        name="startDate"
                        label="Start date"
                        required
                    />

                    <DsDatePicker
                        v-model="hackathon.endDate"
                        class="date"
                        time
                        name="endDate"
                        label="End date"
                        :disable-before-date="hackathon.startDate"
                        required
                    />
                </div>

                <DsMultiselect
                    v-model="hackathon.type"
                    searchable
                    placeholder="Event type"
                    bind-value-only
                    required
                    :options="eventTypes"
                />

                <h4>Awards</h4>

                <!-- TODO: Fix broken UI -->
                <div class="awards">
                    <div v-for="(award, i) in hackathon.awards" :key="i" class="award">
                        <DsInputField v-model="hackathon.awards[i].name" label="Name" />
                        <DsInputField v-model="hackathon.awards[i].description" label="Description" />

                        <DsMultiselect
                            v-model="hackathon.awards[i].winner"
                            searchable
                            placeholder="Winner"
                            bind-value-only
                            :options="projects"
                        />

                        <DsToggleSwitch
                            v-model="hackathon.awards[i].disableOwnProjectVoting"
                            label-on="Users can't vote for their own project for this award"
                            label-off="Prevent users from voting for their own project for this award"
                        />

                        <div class="switch">
                            <DsToggleSwitch
                                v-model="hackathon.awards[i].disabled"
                                class="toggle-switch"
                                label-on="Voting currently disabled for this award"
                                label-off="Click to disable voting for this award"
                            />

                            <DsIcon
                                class="remove"
                                name="trash-2"
                                @click.native="hackathon.awards.splice(i, 1)"
                            />
                        </div>
                    </div>
                    <div class="add-award">
                        <DsFilledButton class="secondary" @click.prevent="addAward">
                            + Add award
                        </DsFilledButton>
                    </div>
                </div>

                <DsTextButton
                    v-if="editing"
                    destructive
                    @click.prevent="deleteConfirm"
                >
                    Delete
                </DsTextButton>

                <DsFilledButton
                    type="submit"
                    class="save"
                    :loading="saving"
                >
                    Save
                </DsFilledButton>
            </template>
        </form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            uploadingPhoto: false,
            hackathon: {
                type: null,
                awards: [],
            },
            maxFileSize: 10485760,
            saving: false,
            loading: false,
            photoUploader: {
                progress: 0,
                file: null,
                interval: 0,
            },
            eventTypes: [
                { value: 'hackathon', label: 'Hackathon' },
                { value: 'rally', label: 'Rally' },
            ],
        };
    },

    mounted() {
        if (!this.isAdmin) {
            this.$router.push({ name: 'home' });

            return;
        }

        if (this.editing) this.load();
    },

    computed: {
        editing() {
            return Boolean(this.hackathonId && this.hackathonId !== 'new');
        },

        hackathonId() {
            return this.$route.params.id;
        },

        title() {
            return this.editing ? 'Edit hackathon' : 'Add hackathon';
        },

        subTitle() {
            return this.editing ? this.hackathon?.name : null;
        },

        projects() {
            const proj = this.editing ? this.getProjectsByHackathon(this.hackathonId) : this.storeProjects;

            return proj.map(({ id, name }) => ({ value: id, label: name }));
        },

        ...mapState({
            storeProjects: ({ projects }) => projects.projects,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        ...mapGetters({
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
        }),
    },

    methods: {
        async load() {
            try {
                this.loading = true;

                const hackathon = await this.$store.dispatch('hackathons/LOAD_HACKATHON', this.hackathonId);

                this.hackathon = {
                    id: this.hackathonId,
                    ...hackathon,
                };
            } catch (e) {
                this.$error({ message: `Error loading hackathon: ${e}` });
            }

            this.loading = false;
        },

        togglePhotoUpload() {
            this.$refs.photoUploader.browse();
        },

        removeImageCheck() {
            this.$confirm({
                title: 'Delete',
                message: 'Are you sure you want to delete this?',
                confirmLabel: 'Yes, delete',
                destructive: true,
            }).then(() => {
                this.removeImage();
            }).catch(() => {});
        },

        removeImage() {
            this.$store.dispatch('hackathons/EDIT_HACKATHON', { ...this.hackathon, image: '' });
        },

        addAward() {
            if (!this.hackathon.awards) {
                this.$set(this.hackathon, 'awards', []);
            }

            this.hackathon.awards.push({});
        },

        async submit() {
            try {
                this.saving = true;

                const action = this.editing
                    ? 'hackathons/EDIT_HACKATHON'
                    : 'hackathons/ADD_HACKATHON';

                await this.$store.dispatch(action, this.hackathon);

                if (this.editing) {
                    this.$router.push({ name: 'hackathon', params: { id: this.hackathonId } });
                } else {
                    this.$router.push({ name: 'home' });
                }
            } catch (e) {
                this.$error({ message: `Error adding hackathon: ${e}` });
            }

            this.saving = false;
        },

        async delete() {
            await this.$store.dispatch('hackathons/DELETE_HACKATHON', this.hackathonId);
            this.$router.push({ name: 'home' });
        },

        handleError(error) {
            this.$error({ message: `Error uploading: '${error}'` });
        },

        async uploadPhoto(file) {
            if (file == null) {
                return null;
            }

            this.uploadingPhoto = true;

            await this.$store.dispatch('hackathons/ADD_PHOTO', { id: this.hackathonId, file })
                .catch(() => {
                    this.$refs.photoUploader.reset();
                    this.uploadingPhoto = false;
                    this.handleError();
                });

            this.$refs.photoUploader.reset();
            this.uploadingPhoto = false;

            return this.$toast({ message: 'Photo added' });
        },

        uploadFile(file) {
            if (file == null) {
                return null;
            }

            return this.$store.dispatch('hackathons/ADD_THUMBNAIL', { id: this.hackathonId, file })
                .then(() => {
                    this.$refs.uploader.reset();
                })
                .catch(() => {
                    this.$refs.uploader.reset();
                    this.handleError();
                });
        },

        async deleteConfirm() {
            try {
                await this.$confirm({
                    title: 'Delete',
                    message: 'Are you sure you want to delete this hackathon?',
                    confirmLabel: 'Yes, delete',
                    destructive: true,
                });

                this.delete();
            } catch (e) {
                //
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.awards {
    background-color: $color-yellow-050;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $spacing-200;
    padding: $spacing-200;
}

.image-controls {
    margin-bottom: $spacing-300;
}
</style>
