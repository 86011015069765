<template lang="html">
    <aside class="projects-sidebar">
        <div
            v-if="hackathonId"
            class="hackathon-banner"
        >
            <router-link :to="{ name: 'hackathon', params: { id: hackathonId } }">
                <img
                    v-if="projectHackathon.image"
                    :src="projectHackathon.image"
                    :alt="projectHackathon.name"
                    class="hackathon-image"
                />
            </router-link>

            <DsDropdown
                v-if="!isRally"
                style="--dropdown-height: 400px"
                dense
            >
                <template #default>
                    <DsOutlineButton class="hackathon-dropdown-button">
                        {{ projectHackathon.name }}
                        <DsIcon name="chevron-down" />
                    </DsOutlineButton>
                </template>

                <template #menu>
                    <ul class="dropdown-menu-list">
                        <li
                            v-for="hackathon in filteredHackathons"
                            :key="hackathon.id"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'hackathon', params: { id: hackathon.id } })"
                        >
                            <!-- <pre>{{ hackathon }}</pre> -->
                            <DsAvatar
                                v-if="hackathon.image"
                                :image-url="hackathon.image"
                                :alt="hackathon.name"
                                style="margin-right: .5rem;"
                            />

                            {{ hackathon.name }}
                        </li>
                    </ul>
                </template>
            </DsDropdown>
        </div>

        <div class="projects">
            <h5 class="title">
                {{ sortedProjects.length }} Projects
            </h5>

            <ProjectsList
                v-if="sortedProjects.length"
                :projects="sortedProjects"
                compact
                @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
            />
        </div>
    </aside>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProjectsList from '@/components/ProjectsList';

export default {
    components: {
        ProjectsList,
    },

    props: {
        projects: Array,
    },

    computed: {
        ...mapState({
            comments: ({ projects }) => projects.comments,
            hackathon: ({ hackathons }) => hackathons.hackathon,
            hackathons: ({ hackathons }) => hackathons.hackathons,
            project: ({ projects }) => projects.project,
        }),

        ...mapGetters({
            getHackathon: 'hackathons/getHackathon',
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        filteredHackathons() {
            return this.hackathons.filter((hackathon) => !hackathon.type || hackathon.type === 'hackathon');
        },

        sortedProjects() {
            // eslint-disable-next-line
            return this.projects?.sort((a, b) => {
                if (a.createdDate > b.createdDate) return 1;
                if (a.createdDate < b.createdDate) return -1;

                return 0;
            });
        },

        isRally() {
            return this.activeHackathon?.type === 'rally';
        },

        hackathonId() {
            if (this.$route.name === 'project' && this.project.hackathonId) return this.project.hackathonId;

            if (this.$route.name === 'hackathon') return false;
            // if (this.$route.name === 'hackathon' && this.hackathon.id) return this.hackathon.id;

            return this.$route.params.id;
        },

        projectHackathon() {
            return this.getHackathon(this.hackathonId);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.projects-sidebar {
    border-radius: $border-radius;
    overflow: hidden;
    max-height: calc(100vh - 104px);

    @media($small) {
        margin: 0;
    }
}

.hackathon-banner {
    padding: $spacing-200;
    text-align: center;
}

.hackathon-image {
    border-radius: $border-radius;
    max-height: 220px;
    width: auto;
    margin-bottom: $spacing-200;
}

.projects {
    height: calc(100vh - 104px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    @include custom-scroll-bar;
}

aside {
    background: $color-gray-050;
    border-radius: $border-radius;
    @include custom-scroll-bar;
}

.back-button {
    margin: $spacing-100;
    width: calc(100% - $spacing-200);
}

header {
    text-align: center;
    padding: $spacing-200;
}

.title {
    padding: $spacing-200;
}

.hackathon-dropdown-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.hackathon-image-frame {
    background-color: blue;
    width: 48px;
    height: 48px;
    overflow: hidden;
}

.hackathon-image-dropdown {
    // width: 120%;
    width: auto;
    height: 480px;
}
</style>
