<template>
    <div>
        <portal to="title">
            All Projects
        </portal>

        <portal to="nav-actions">
            <DsFilledButton gray @click="$router.push({ name: 'ideas' })">
                Ideas
            </DsFilledButton>

            <DsFilledButton gray @click="$router.push({ name: 'projects.new' })">
                Create project
            </DsFilledButton>
        </portal>

        <ProjectsList
            v-if="filteredProjects.length"
            :projects="filteredProjects"
            @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProjectsList from '@/components/ProjectsList';

export default {
    components: {
        ProjectsList,
    },

    computed: {
        ...mapState({
            projects: ({ projects }) => projects.projects,
        }),

        ...mapGetters({
            getAwardByProject: 'projects/getAwardByProject',
        }),

        filteredProjects() {
            return this.projects.filter(({ hackathonId }) => Boolean(hackathonId));
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.projects-page {
    margin: $spacing-200;
    overflow: hidden;
    background: $color-white;
    border-radius: $border-radius;
}

.scroll-wrap {
    height: calc(100vh - 120px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    @include custom-scroll-bar;
}
</style>
