<template>
    <div class="projects-page">
        <section class="scroll-wrap">
            <portal to="title">
                Hall of Fame
            </portal>

            <ProjectsList
                :projects="winningProjects"
                @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
            />
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProjectsList from '@/components/ProjectsList';

export default {
    components: {
        ProjectsList,
    },

    computed: {
        ...mapState({
            projects: ({ projects }) => projects.projects,
            hackathons: ({ hackathons }) => hackathons.hackathons,
        }),

        ...mapGetters({
            winningProjectIds: 'hackathons/winningProjectIds',
        }),

        winningProjects() {
            return this.winningProjectIds?.map((projectId) => this.projects?.find(({ id }) => id === projectId));
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .projects-page {
        margin: $spacing-200;
        overflow: hidden;
        background: $color-white;
        border-radius: $border-radius;
    }

    .scroll-wrap {
        height: calc(100vh - 120px);
        overflow: auto;
        display: flex;
        flex-direction: column;
        @include custom-scroll-bar;
    }

    h2 {
        margin-bottom: $spacing-100;
    }
</style>
