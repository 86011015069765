<template lang="html">
    <div class="project-images">
        <img
            v-if="activeImage"
            :src="activeImage"
            class="active-image cursor-pointer"
            @click="isOpen = true"
        />

        <div v-if="hasPhotos" class="thumbnails">
            <img
                v-if="project.thumbnail"
                :src="project.thumbnail"
                class="thumbnail cursor-pointer"
                @click="setActiveImage(project.thumbnail)"
            />

            <img
                v-for="(photo, index) in project.photos"
                :key="index"
                :src="photo"
                class="thumbnail cursor-pointer"
                @click="setActiveImage(photo)"
            />
        </div>

        <DsModal
            :is-open="isOpen"
            show-close
            size="fluid"
            title="Project images"
            :sub-title="project.name"
            @close="isOpen = false"
        >
            <img :src="activeImage" class="max-size-image" />
        </DsModal>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        project: Object,
    },

    data() {
        return {
            isOpen: false,
            activeImage: null,
        };
    },

    mounted() {
        this.activeImage = this.project?.thumbnail || null;
    },

    watch: {
        // eslint-disable-next-line
        'project.id'(value) {
            this.activeImage = this.project?.thumbnail || null;
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            users: ({ users }) => users.users,
            hackathons: ({ hackathons }) => hackathons.hackathons,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        hasPhotos() {
            return this.project?.photos?.length > 0;
        },
    },

    methods: {
        setActiveImage(image) {
            this.activeImage = image;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.project-images {
    display: grid;
    grid-gap: $spacing-200;
    padding: $spacing-200;
    z-index: 3;
    align-items: center;
}

.thumbnails {
    display: inline-flex;
    grid-gap: $spacing-200;
    justify-content: center;
}

.thumbnail {
    height: 80px;
    width: auto;
    border-radius: $border-radius;
}

.project-photos {
    height: 100px;
    border-radius: $border-radius;
    background: red;
}

.max-size-image {
    border-radius: $border-radius;
    min-width: 300px;
    height: auto;
    max-height: calc(100vh - 104px);
    margin: 0 auto;
}

.active-image {
    border-radius: $border-radius;
    max-height: 40vh;
    margin: 0 auto;
}
</style>
