<template>
    <div>
        <header>
            <aside>
                <h4>Ideas</h4>
                <p>Need a project? Check out these ideas submitted by fellow Keapers.</p>
            </aside>

            <DsFilledButton @click="$router.push({ name: 'ideas.new' })">
                Submit idea
            </DsFilledButton>
        </header>

        <ProjectsList
            v-if="ideas.length"
            :projects="ideas"
            @selected="openProject"
        />

        <div class="ideas-used">
            <h4>Ideas implemented</h4>
            <p>Thank you for submitting your ideas!</p>
        </div>

        <ProjectsList
            v-if="ideasUsed.length"
            :projects="ideasUsed"
            @selected="openProject"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ProjectsList from '@/components/ProjectsList';

export default {
    components: {
        ProjectsList,
    },

    computed: {
        ...mapState({
            projects: ({ projects }) => projects.projects,
        }),

        ideas() {
            return this.projects.filter(({ ideaAuthor, hackathonId }) => ideaAuthor && !hackathonId)
                ?.sort((a, b) => {
                    if (a.lastUpdated > b.lastUpdated) return -1;
                    if (a.lastUpdated < b.lastUpdated) return 1;

                    return 0;
                });
        },

        ideasUsed() {
            return this.projects.filter(({ ideaAuthor, hackathonId }) => ideaAuthor && hackathonId)
                ?.sort((a, b) => {
                    if (a.lastUpdated > b.lastUpdated) return -1;
                    if (a.lastUpdated < b.lastUpdated) return 1;

                    return 0;
                });
        },
    },

    methods: {
        openProject(project) {
            this.$router.push({ name: project.hackathonId ? 'project' : 'idea', params: { id: project.id } });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.projects-page {
    margin: $spacing-200;
    overflow: hidden;
    background: $color-white;
    border-radius: $border-radius;
}

.scroll-wrap {
    height: calc(100vh - 120px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    @include custom-scroll-bar;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: $spacing-200 $spacing-200 0;
}

.ideas-used {
    margin-top: $spacing-200;
    padding: $spacing-200 $spacing-200 0;
    color: $color-green;
}
</style>
